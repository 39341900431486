import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  public formResponse: string | any ;
  public formStatus: string | any;
  public sendEmail(e: Event) {
    e.preventDefault();
    var contactForm = <HTMLFormElement>document.getElementById('contact-form');
    emailjs.sendForm('service_m718y71', 'template_contact', e.target as HTMLFormElement, 'aSn5bM0vpVb2jhaZn')
      .then(() => {
        //reset here
        contactForm.reset();
        this.formStatus = 'success';
        this.formResponse = 'Su mensaje ha sido enviado con éxito, nos pondremos en contacto con usted en breve.';
      }, () => {
        this.formStatus = 'danger';
        this.formResponse = 'Hubo un error al enviar su mensaje.';
      });
  }

  ngOnInit(): void {
  }

}
